.notify {
    @apply p-3 rounded-md mt-10;
}

.notify.success {
    @apply bg-green-400;
}

.notify.error {
    @apply bg-red-400;
}

.notify.warning {
    @apply bg-yellow-400;
}