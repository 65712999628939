.blob::before {
    content: '';
    background: black;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    background: #f97316;
    box-shadow: 0 0 0 0 rgba(249, 115, 22, 1);
    transform: scale(1);
    animation: pulse-red 2s infinite;
    float: right;
    position: relative;
    top: -2px;
    right: 9px;    
}


.blob-pulse-button {
    border-radius: 5%;
    animation: pulse-red 2s infinite;
}
  
.blob-shake-icon {
    display: inline-block;
    animation-name: shake;
    animation-duration: 0.2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% {
      transform: rotate(-5deg);
    }
    50% {
      transform: rotate(5deg);
    }
    100% {
      transform: rotate(-5deg);
    }
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(249, 115, 22, 0.7);
    }
    
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(249, 115, 22, 0);
    }
    
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(249, 115, 22, 0);
    }
}

.content-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    border: 1px solid;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,0.5);
    z-index: 9999;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* BELL ICON NOTIFICATION */ 
.notify-bells {
    height: 39px;
    line-height: 33px;
}
  .bell-icon {
    margin: auto;
  }
  
  .bell-icon svg {
    margin: auto;
    width: 80%;
    stroke: #fb923c;;
  }
  
  .bell-icon__group {
    transform-origin: 50% 2px;
    animation: animateGroup 2.3s infinite;
    animation-timing-function: ease-in-out;
  }
  
  .bell-icon__ball {
    transform-origin: 50% 2px;
    animation: animateBall 2.3s infinite;
    animation-timing-function: ease-in-out;
  }
  
  .notification-amount {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 20%;
    right: 24%;
    width: 25px;
    height: 25px;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Copse", serif;
    font-size: 14px;
  }
  
  .notification-amount span {
    position: relative;
  }
  
  .notification-amount::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #f72918;
    border-radius: 50%;
    z-index: 0;
    transform: scale(0);
    animation-duration: 800ms;
    animation-fill-mode: both;
  }
  
  @keyframes animateGroup {
    0%, 100% {
      transform: rotate(-8deg);
    }
    17.539999999999996% {
      transform: rotate(0deg);
    }
    35.07999999999999% {
      transform: rotate(-20deg);
    }
    48.260000000000005% {
      transform: rotate(20deg);
    }
    56.715% {
      transform: rotate(-20deg);
    }
    63.97800000000001% {
      transform: rotate(8deg);
    }
    74.2425% {
      transform: rotate(-15deg);
    }
    79.4715% {
      transform: rotate(-7deg);
    }
  }
  
  @keyframes animateBall {
    0%, 100% {
      transform: translateX(-6.5%);
    }
    17.539999999999996% {
      transform: translateX(0%);
    }
    21.8975% {
      transform: translateX(-1%);
    }
    35.07999999999999% {
      transform: translateX(11%);
    }
    48.260000000000005% {
      transform: translateX(-11%);
    }
    51.648% {
      transform: translateX(0%);
    }
    58.918% {
      transform: translateX(10%);
    }
    66.198% {
      transform: translateX(-11%);
    }
    74.2425% {
      transform: translateX(11%);
    }
    81.287% {
      transform: translateX(-11%);
    }
  }
  
  @keyframes bounce {
    0% {
      transform: scale(0);
    }
    14% {
      transform: scale(1.15);
    }
    28% {
      transform: scale(1);
    }
    42% {
      transform: scale(1.15);
    }
    70% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  
.text-info-invite {
    width: 0;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
}

.notify-bells:hover .text-info-invite {
    width: 143px;
    animation: expandWidthTeste 2s 1;
}

.notify-bells:not(:hover) .text-info-invite {
  animation: shrinkWidthTeste 2s 1;
}

@keyframes expandWidthTeste {
  0% { width: 0; }
  100% { width: 143px; }
}

@keyframes shrinkWidthTeste {
  0% { width: 143px; }
  100% { width: 0; }
}

@media (max-width: 640px) {
  .hidden-item {
      opacity: 0;
  }
  .scroll-efect-text {
      transition: all 0,4;
      white-space: nowrap;
  }
  
  .scroll-button {
      height: 60px;
      width: 150px;
      white-space: nowrap;
      transition: width 0.1s ease-in-out;
  }
  .scroll-effect {
      width: 55px !important;
  }
}

.tip-invite {
  background: #fb923c;
  color: white;
  font-size: 8px;
  position: relative;
  top: 0;
  right: 25px;
  line-height: 2;
  border-radius: 100%;
  height: 15px;
  width: 15px;
  animation: fadeEffect 2s forwards;
}

.notify-bells:hover > .tip-invite {
  display: none;
}


@keyframes fadeEffect {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
