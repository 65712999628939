input.validate-fail {
    @apply border-red-500;
}

input.validate-passed {
    @apply border-green-500;
}

input.validate-attention {
    @apply border-yellow-500;
}